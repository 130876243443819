// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';

import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import WaitingTime from 'components/promptService/waitingTime';
import TextSection from 'components/units/unitPages/details/TextSection';
import TextSectionEquipe from 'components/units/unitPages/details/TextSectionEquipe';
import MainServices from 'components/promptService/mainServices';
import OutDoor from 'components/units/unitPages/unit/outdoorService';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  width: ${props => props.desktop && '50%'};

  @media ${device.laptop} {
    width: ${props => props.width};
  }
`;

const HR = styled.hr`
  width: 100%;
  margin: 0;
  border: 0 solid #c6c8cc;

  @media ${device.laptop} {
    display: none;
  }
`;

const HRS = styled.hr`
  width: 79%;
  margin: 0;
  border: 0 solid #c6c8cc;

  @media ${device.laptop} {
    display: none;
  }
`;

const HRMo = styled.hr`
  display: none;
  width: 100%;
  margin: 0;
  border: 0 solid #c6c8cc;

  @media ${device.laptop} {
    display: flex;
  }
`;

const Container = styled.div`
  display: grid;
  grid-column: 2 / -2;
  grid-template-columns: repeat(12, 70px);
  column-gap: 30px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: ${props =>
      props.isCDICardio ? 'column' : 'column-reverse'};
  }
`;

const Teste = styled.div`
  display: flex;
  width: ${props => props.cards && '50%'};
  flex-direction: ${props => props.cards && 'column'};

  @media ${device.laptop} {
    display: ${props => props.display};
    flex-direction: column;
    width: ${props => props.cards && '100%'};
  }
`;

const RenderMobile = styled.div`
  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`;

const GridMainServices = styled.div`


  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    background: #f4f5f7;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);
  return (
    <>
      {' '}
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <GridMainServices>
          <Part gridColumn={'2 / -2'} id="tempo-de-espera">
            <RenderMobile>
              <Teste cards>
                <WaitingTime isCDICardio isJustCards="Cardiologia" noPadding />
              </Teste>
            </RenderMobile>
          </Part>
        </GridMainServices>
        <Container isCDICardio>
          <Part gridColumn={'1 / -1'}>
            <Teste>
              <Part gridColumn={'1 / span 5'} width={'100%'} desktop>
                <TextSectionEquipe
                  markdown={getMarkdown(
                    page,
                    'cklqvg0nszfti0b68fi08mpz5',
                    true
                  )}
                  gridArea="ABT"
                  isAbout
                  
                  isShowing
                  isShort
                  isCDICardio
                  isAddressAndTimes
                />
                <HRS />
              </Part>
              <Teste cards display="none">
                <WaitingTime isCDICardio isJustCards="Cardiologia" />
              </Teste>
            </Teste>
          </Part>
          <Part gridColumn={'1 / -8'}>
            <TextSection
              markdown={getMarkdown(page, 'cklqx1v80zspq0b6858or6zff', true)}
              gridArea="ABT"
              isAbout
              isAboutMobile
              isShowing
              isShort
              isPadding
            />
            <HR />
            <HRMo />
            <MainServices
              markdown={getMarkdown(page, 'cklqxf42ozgzz0974lksv7nn5', true)}
              isCDICardio
            />
          </Part>
        </Container>
        <Part gridColumn={'2 / -2'}>
          <OutDoor
            pageName="Bela Vista"
            Outdor
            url="/unidades/bela-vista/detalhes"
          />
        </Part>
      </GridContainer>
    </>
  );
};

const CardiologyRoom = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'cklqus7eoywex0b78hhwqtemx', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cklqtosfcyo520b78ji7w3xmd" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  activeBreaknews
                  breaknews {
                    id
                    name
                    type
                    singletexts
                    markdown
                  }
                  newnessBannerActive
                  newnessBanner {
                    id
                    name
                    type
                    markdown
                  }

                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  map {
                    id
                    place
                    zoom
                    assetpicker {
                      handle
                      width
                      height
                      url
                    }
                    coordenadas {
                      latitude
                      longitude
                    }
                    desktop
                    mobile
                  }
                  fragments {
                    id
                    name
                    type
                    markdown
                    singletexts
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <CardiologyRoom page={response.gcms.site.pages} />;
      }}
    />
  );
};
